.react-calendar {
  width: 100%;
  max-width: 100%;
  background: transparent;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: none;
}

.react-calendar__navigation button {
  pointer-events: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  font-size: 12px;
  color: #959AB4;
}

.react-calendar__month-view__weekdays abbr {
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid #e4e5ea;
  background-color: #CBCEDF;
  color: #959AB4;
  font: inherit;
  font-size: 14px;
}

.react-calendar__tile.tile--green::before {
  position: absolute;
  content: '';
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #99CDA2;
}

.react-calendar__tile.tile--red::before {
  position: absolute;
  content: '';
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #E48B83;
}

.react-calendar__tile:disabled {
  background-color: #CBCEDF;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #CBCEDF;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #CBCEDF;
}

.react-calendar__tile--hasActive {
  background: #CBCEDF;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #CBCEDF;
}

.react-calendar__tile--active {
  background: #D4F0E1;
  color: #35373F
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #CBCEDF;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #CBCEDF;
}